import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {GetOrder} from "../api/order";
import Order from '../components/order'
import {ColorRing} from "react-loader-spinner";
import Header from "../components/header";
import Loader from "../components/loader";
const OrderPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [orderId] = useState(searchParams.get("orderId"))
    const [order, setOrder] = useState(null)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (orderId) {
            setLoading(true)
            GetOrder(orderId).then(setOrder).finally(() => setLoading(false))
        }
    }, [orderId])

    return <div className={"page"}>
        <Header title={"Мои поездки"}/>
        <Loader
            visible={loading}
        />
        {order && <Order order={order}/>}
    </div>
}

export default OrderPage