import {Link, useLocation, useNavigate} from "react-router-dom";
import back from "../../icons/back.svg";
import './style.css'

const Index = ({title, onBack}) => {
    const location = useLocation()
    const navigate = useNavigate()

    const goBack = ()=>{
        if (onBack) {
            onBack()
            return
        }
        navigate(-1)
    }

    return <div className={"header"}>
        {onBack || location.key !== 'default' ?<>
            <div className={"header-back-button"} onClick={goBack}>
                <img src={back}/>
            </div>
            <div className={"header-title"}>
                {title}
            </div>
        </> : <div className={"header-text"}>{title}</div>}
    </div>
}

export default Index;