const API = process.env.REACT_APP_API_URL;

let token = {}
let verified = false

const SetAuthToken = (tkn, vrfd) => {
    token = tkn
    verified = vrfd
}

const IsVerified = () => {
    return verified
}

const makeSearchParams = (params) => {
    if (!params) {
        return "";
    }
    const keys = Object.keys(params).filter(p => params[p] !== undefined && params[p] !== null)
    if (keys.length === 0) {
        return ""
    }
    const p = keys.reduce((prev, k) => [...prev, ...Array.isArray(params[k]) ? params[k].map(v => [k, v]) : [[k, params[k]]]], [])
    return "?" + (new URLSearchParams(p)).toString()
}

const Get = (url, args, signal) => {
    return new Promise((resolve, reject) =>
        fetch(API + "/" + url + makeSearchParams(args), {
            signal: signal,
            headers: {
                "authorization": token,
            }
        })
            .then(r => {
                if (!r.ok) {
                    throw r;
                }
                return r.json()
            })
            .then(resolve)
            .catch((error) => {
                if (signal && (typeof error === "string" || error.name === "AbortError")) {
                    console.log("aborted", url, args)
                    return
                }
                reject(error)
            })
    )
}

const Post = (url, args, body, signal) => {
    return new Promise((resolve, reject) =>
        fetch(API + "/" + url + makeSearchParams(), {
            method: "POST",
            signal: signal,
            body: JSON.stringify(body),
            headers: {
                "authorization": token,
            }
        })
            .then(r => {
                if (!r.ok) {
                    throw r;
                }
                return r.json()
            })
            .then(resolve)
            .catch(reject))
}

const Delete = (url, args,  signal) => {
    return new Promise((resolve, reject) =>
        fetch(API + "/" + url + makeSearchParams(), {
            method: "DELETE",
            signal: signal,
            headers: {
                "authorization": token,
            }
        })
            .then(r => {
                if (!r.ok) {
                    throw r;
                }
                return r
            })
            .then(resolve)
            .catch(reject))
}


export {API, makeSearchParams, Get, Post,SetAuthToken, Delete, IsVerified}
