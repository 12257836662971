import {API, Get, makeSearchParams} from "./index";

let ordersController;

const GetOrders = (offset,limit, id) => {
    if (ordersController) {
        ordersController.abort("deduplication")
    }
    ordersController = new AbortController()
    return Get("orders", {offset, limit, with: ['Vehicle','Route','FromStation','ToStation']}, ordersController.signal)
}

export {GetOrders}
