import {useEffect, useState} from "react";
import {sha256} from "js-sha256"

const Auth = () => {
    const [userId, setUserId] = useState("1001")
    const [lat, setLat] = useState(56.854357)
    const [lng, setLng] = useState(35.907125)
    const [points, setPoints] = useState()
    const [secret, setSecret] = useState("12345678")
    const [verified, setVerified] = useState(false)

    const login = () => {
        const ts = Math.round(Date.now() / 1000)
        const urps = new URLSearchParams();
        if (lat) {
            urps.set("lat", lat)
        }
        if (lng) {
            urps.set("lng", lng)
        }
        if (points) {
            urps.set("points", points)
        }
        if (userId) {
            urps.set("user_id", userId)
        }
        if (verified) {
            const payload = [lat, lng, points].filter(a => !!a).join(",")
            const message = payload + "," + [ts, userId, secret].filter(a => !!a).join(",")
            console.log(message)
            urps.set("timestamp", ts)
            urps.set("sign", sha256(message))
        }
        window.location.href = "/?" + urps.toString()
    }
    return <div className={"page"}>
        <div>
            <div>User ID</div>
            <input placeholder={"user id"} type={"text"} value={userId} onChange={e => setUserId(e.target.value)}/>
        </div>
        <div>
            <div>Latitude</div>
            <input placeholder={"lat"} type={"text"} value={lat} onChange={e => setLat(e.target.value)}/>
        </div>
        <div>
            <div>Longitude</div>
            <input placeholder={"lng"} type={"text"} value={lng} onChange={e => setLng(e.target.value)}/>
        </div>
        <div>
            <div>Points</div>
            <input placeholder={"points"} type={"text"} value={points} onChange={e => setPoints(e.target.value)}/>
        </div>
        <div>
            <input type={"checkbox"} value={verified} onChange={e => setVerified(e.target.checked)}/> Verified user
        </div>
        {verified && <div>
            <div>Secret</div>
            <input placeholder={"secret"} type={"text"} value={secret} onChange={e => setSecret(e.target.value)}/>
        </div>}
        <br/>
        <button onClick={login}>Login</button>
    </div>
}

export default Auth