import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState, useRef} from "react";
import {GetOrders} from "../api/orders";
import bus from "../icons/bus.svg"
import Moment from "react-moment";
import 'moment/locale/ru';
import moment from "moment";
import question from "../icons/question.svg"
import Index from '../components/header'
import Loader from "../components/loader";
import notFoundIcon from '../icons/notfound.svg'
import Header from "../components/header";

moment.calendarFormat = function (myMoment, now) {
    var diff = myMoment.diff(now, 'days', true);
    if (diff === 0) {
        return 'sameDay'
    }
    if (diff === -1) {
        return 'lastDay'
    }
    return 'sameElse'
};

const Orders = () => {
    let navigate = useNavigate();
    const [orders, setOrders] = useState({})
    const [offset, setOffset] = useState(0)
    const [loading, setLoading] = useState(false)
    const [loadingAttempts, setLoadingAttempts] = useState(0)

    const loadOrders = (data) => {
        setLoading(true)
        GetOrders(offset, 10).then(list => {
            setOrders(list.reduce((prev, item) => {
                const date = new Date(item.created_at).toISOString().split('T')[0]
                return {...prev, [date]: [...(prev[date] || []), item]}
            }, orders))
        }).finally(() => {
            setLoading(false)
            setLoadingAttempts(loadingAttempts + 1)
        })
    }

    useEffect(() => {
        loadOrders()
    }, [offset])

    const onScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setOffset(Object.keys(orders).reduce((prev, item) => prev + orders[item].length, 0))
        }
    }

    return <div className={"page"}>
        <Header title={"Мои поездки"}/>
        <div className={"orders"}>
            <Loader visible={loading}/>
            {!loading && loadingAttempts > 0 && Object.keys(orders).length === 0 &&
                <div style={{textAlign: "center", marginTop: 192}}>
                    <img src={notFoundIcon}/>
                    <div style={{textAlign: "left", position:"fixed", bottom:32}}>
                        <div style={{fontSize:19, fontWeight:600,letterSpacing:"-0.02em"}}>Нет купленных билетов</div>
                        <div style={{fontSize:16, letterSpacing:"-0.015em"}}>Здесь отображаются купленные билеты на общественный транспорт</div>
                    </div>
                </div>}
            <div className={"orders-list"} onScroll={onScroll}>
                {Object.keys(orders).map(date => <div key={date}>
                    <div className={"orders-list-date"}>
                        <div className={"orders-list-date-text"}>
                            <Moment locale="ru" calendar={{
                                lastDay: '[Вчера]',
                                sameDay: '[Сегодня]',
                                sameElse: 'DD MMMM',
                            }}>{date}</Moment>
                        </div>
                    </div>

                    {orders[date].map(o => <div key={o.id} className={"orders-list-item"}
                                                onClick={() => navigate("/order?orderId=" + o.id)}>
                        <div className={"orders-list-item-vehicle"}>
                            <img src={bus}/>
                        </div>
                        <div className={"orders-list-item-info"}>
                            <div className={"orders-list-item-route"}>{o.route?.number}</div>
                            <div
                                className={"orders-list-item-stations"}>{o.from_station?.name} {o.to_station ? <>&#8722;{o.to_station.name}</> : null}</div>
                        </div>
                        <div className={"orders-list-item-time"}>
                            {new Date(o.created_at).getHours().toString().padStart(2, "0")}:{new Date(o.created_at).getMinutes().toString().padStart(2, "0")}
                        </div>
                        <div className={"orders-list-item-icon"}>
                            <a href={"mailto:support@sberintegro.ru?subject=Вопрос по заказу " + o.id + "&body=%0A%0A%0A%0A------------------------------------------------------%0AПожалуйста, не удаляйте данные ниже.%0AЗаказ: " + o.id + "%0AUser-Agent: " + window.navigator.userAgent + "%0AВремя: " + new Date().toLocaleString()}><img src={question}/></a>
                        </div>
                    </div>)}
                </div>)}
                <div style={{height: 120}}/>
            </div>
        </div>
    </div>
}


export default Orders