import QRCode from "react-qr-code";
import {useEffect, useState} from "react";
import "./style.css"
import Vehicle from "../../components/vehicle"
import pathIcon from "../../icons/path.svg"
import moment from "moment";
import Moment from "react-moment";
import receiptIcon from "../../icons/receipt.svg"
import {API} from "../../api"
import 'react-slideshow-image/dist/styles.css'
import {Slide} from 'react-slideshow-image';
import html2canvas from "html2canvas";
import sliderPointIcon from "../../icons/slider-point.svg"
import sliderPointSelectedIcon from "../../icons/slider-point-selected.svg"
import clockIcon from "../../icons/clock.svg"
import arrowRightIcon from "../../icons/arrow-right.svg"
import {useNavigate} from "react-router-dom";

const Order = ({order}) => {
    const [ticket, setTicket] = useState(0)
    const navigate = useNavigate()
    const exportTicket = () => {
        const input = document.getElementById("root")
        html2canvas(input)
            .then(canvas => {
                const image = canvas.toDataURL('image/png')
                const a = document.createElement('a')
                a.setAttribute('download', 'ticket-' + order.tickets[ticket].id + '.png')
                a.setAttribute('href', image)
                a.click()
                canvas.remove()
            })
    }
    return <div>
        <div onClick={()=>navigate("/orders")} style={{display:"flex", gap:12,  borderRadius: 12, paddingTop:12, paddingBottom:14,paddingRight:16, paddingLeft:16, background: "white"}}>
            <div><img src={clockIcon} style={{display:"block"}}/></div>
            <div style={{ lineHeight:"24px",width: "100%"}}>История поездок</div>
            <div><img src={arrowRightIcon} style={{display:"block"}}/></div>
        </div>
        <Slide arrows={false} transitionDuration={10} autoplay={false} easing={"linear"} onChange={(i, ni) => setTicket(ni)}>
            {order.tickets.map((t, i) => <div className={"order-wrapper"}>
                <div className={"order"}>
                    <div style={{textAlign: "center"}}>
                        <QRCode key={i} style={{display:"block", width: "100%"}} size={"100%"} value={t.digest}/>
                    </div>
                    <Vehicle vehicle={{...order.attributes.vehicle, route: order.route}}/>
                    <div style={{display: "flex", marginTop: 8, gap: 12}}>
                        <div style={{paddingTop: 8}}><img src={pathIcon}/></div>
                        <div>{order.from_station.name} - {order.to_station ? order.to_station.name : order.attributes.vehicle.attributes.stations.slice(-1)[0].name}</div>
                    </div>
                    <div style={{color: "#898989", marginLeft: 34}}>Путь</div>
                    <div style={{marginLeft: 16, marginTop: 12}}>Стоимость
                        билета: <strong>{t.price / 100} &#x20bd;</strong> ·
                        заказа: <strong>{order.price / 100} &#x20bd;</strong></div>
                    <div style={{display: "flex", paddingLeft: 16, marginTop: 12, gap: 12}}>
                        <div style={{width: "100%"}}>
                            <div onClick={exportTicket} style={{
                                textAlign: "center",
                                background: "#1414140F",
                                paddingTop: 7,
                                paddingBottom: 6,
                                borderRadius: 8,
                                border: 0,
                                width: "100%"
                            }}>
                                <div style={{fontSize: 15, fontWeight: 600, color: "#141414"}}>Скачать билет</div>
                                <div style={{color: "#898989", fontSize: 13}}><Moment
                                    format={"DD MMMM · HH:mm"}>{order.created_at}</Moment>
                                </div>
                            </div>
                        </div>
                        {order.attributes?.receipts?.length > 0 && <div>
                            <a href={order.attributes?.receipts[0]} target={"_blank"}>
                                <button
                                    style={{
                                        height: 48,
                                        width: 48,
                                        borderRadius: 8,
                                        background: "#1414140F",
                                        border: 0
                                    }}>
                                    <img src={receiptIcon}/>
                                </button>
                            </a>
                        </div>}
                    </div>
                    <div style={{paddingLeft: 16, marginTop: 16, fontSize: 14, color: 'rgb(137, 137, 137)'}}>Номер
                        билета: {t.id.slice(-8)} · заказа: {order.id.slice(-8)} </div>
                </div>
            </div>)}
        </Slide>
        {order.tickets.length > 1 && <div style={{textAlign: "center"}}>
            {order.tickets.map((t, i) => <img style={{marginLeft: 4, paddingBottom: i === ticket ? 0 : 1}}
                                              src={i === ticket ? sliderPointSelectedIcon : sliderPointIcon}/>)}
        </div>}
    </div>
}


export default Order;