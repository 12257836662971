import './App.css';
import {Routes, Route, useSearchParams} from "react-router-dom";
import Vehicles from "./pages/vehicles";
import Bindings from "./pages/bindings";
import Profile from "./pages/profile";
import History from "./pages/history";
import OrderPage from "./pages/order";
import Orders from "./pages/orders";
import Cards from "./pages/cards";
import Auth from "./pages/auth"
import {useEffect, useState} from "react";
import {API, SetAuthToken} from "./api"
import Loader from "./components/loader";

function checkSign(query) {
    const searchParams = new URLSearchParams(query)
    const token = searchParams.get("token")
    const sign = searchParams.get("sign")
    const userId = searchParams.get("user_id")
    const timestamp = parseInt(searchParams.get("timestamp"))
    searchParams.delete("sign")
    searchParams.delete("user_id")
    searchParams.delete("timestamp")
    let ar = []
    searchParams.forEach((v, n) => {
        if (v) {
            ar.push(v.toString())
        }
    })
    return new Promise((resolve, reject) => {
        if (token) {
            fetch(API + "/auth/jws",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: token,
                    })
                }).then(r => r.json()).then(data => {
                SetAuthToken(data.token, data.verified)
                resolve(data.token, data.verified)
            })
        } else {
            fetch(API + "/auth/secret",
                {
                    method: "POST",
                    body: JSON.stringify({
                        payload: ar.join(","),
                        user_id: userId,
                        timestamp: timestamp,
                        sign: sign,
                    })
                }).then(r => r.json()).then(data => {
                SetAuthToken(data.token, data.verified)
                resolve(data.token, data.verified)
            })
        }
    })
}


function App() {
    const [searchParams] = useSearchParams()
    const [auth, setAuth] = useState(null)
    useEffect(() => {
        checkSign(searchParams.toString()).then(setAuth)
    }, [])
    return auth ? <Routes>
        <Route path="/" element={<Vehicles/>}></Route>
        <Route path="/order" element={<OrderPage/>}></Route>
        <Route path="/cards" element={<Cards/>}></Route>
        <Route path="/orders" element={<Orders/>}></Route>
        <Route path="/history" element={<History/>}></Route>
        <Route path="/profile" element={<Profile/>}></Route>
        <Route path="/bindings" element={<Bindings/>}></Route>
        <Route path="/auth" element={<Auth/>}></Route>
    </Routes> : <Loader visible={true}/>
}

export default App;
