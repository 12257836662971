import Header from "../components/header";
import moneyIcon from '../icons/money.svg'
import switchOff from '../icons/switch-off.svg'
import switchOn from '../icons/switch-on.svg'

const Approve = ({savePayment, setSavePayment, onBack}) => {
    return <div className={"page"}>
        <Header title={"Способ оплаты"} onBack={onBack}/>
        <div className={"vehicles"} style={{paddingTop: 0, marginTop: 8}}>
            <div style={{display: "flex", paddingTop: 12, justifyContent: "space-between", paddingBottom: 12}}>
                <div>
                    <img src={moneyIcon} style={{paddingLeft: 8, paddingTop: 8}}/>
                </div>
                <div style={{paddingLeft: 16, width: "100%"}}>
                    <div>Банковская карта или SberPay</div>
                    <div style={{
                        color: "#898989",
                        fontSize: 14
                    }}>Необходимо добавить
                    </div>
                </div>
            </div>
            <div>
                <hr style={{border: "0.5px solid #8989894D", marginLeft: 8, marginRight: 8}}/>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", paddingTop: 8}}>
                <div style={{paddingLeft: 16, width: "100%"}}>
                    <div>Запомнить</div>
                    <div style={{
                        color: "#898989",
                        fontSize: 14
                    }}>Для следующих покупок
                    </div>
                </div>
                <div style={{color: "#1BA136", paddingRight: 8}} onClick={()=>setSavePayment(!savePayment)}>
                    {savePayment ? <img src={switchOn}/> : <img src={switchOff}/>}
                </div>
            </div>
        </div>
    </div>
}

export default Approve