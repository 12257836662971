import bus from "../../icons/bus.svg";
import pinSelected from "../../icons/pin-selected.svg";
import pin from "../../icons/pin.svg";
import {useRef} from "react";
import './style.css'

const prepareLicenseNumber = (ln) => {
    return ln.replaceAll(' ', '').replace(/([a-z\u0430-\u044f]{1,2})(\d{3})([a-z\u0430-\u044f]{2})(\d{2,3})/i, "$1 $2 $3 $4").replace(/([\u0430-\u044f]{1,2})(\d{3})(\d{2,3})/i, "$1 $2 $3")
}

const Index = ({
                   vehicle,
                   selectedVehicle,
                   loading,
                   setVehicle,
               }) => {
    const select = () => {
        if (setVehicle && !loading) {
            setVehicle(vehicle)
        }
    }

    return <div key={vehicle.id} className={"vehicle"} onClick={e => select()}>
        <div className={"vehicle-bus-icon"}><img src={bus}/></div>
        <div className={"vehicle-info"}>
            <div>
                <div className={"vehicle-route"}>
                    <div className={"vehicle-route-number"}>{vehicle.route.number}</div>
                </div>
                <div className={"vehicle-station"}>
                    <div
                        className={"vehicle-station-text"}>До {vehicle.attributes?.stations[vehicle.attributes.stations.length - 1]?.name}
                    </div>
                </div>
                <div style={{display: "flex", gap: 8}}>
                    <div
                        className={"vehicle-license-number"}>{prepareLicenseNumber(vehicle.license_number)}
                    </div>
                    <div
                        className={"vehicle-license-number"}>{vehicle.board_number}
                    </div>
                </div>
            </div>
            {setVehicle && <div className={"vehicle-selection"}>
                <div className={"vehicle-pin"}>
                    {vehicle.id === selectedVehicle?.id ? <img src={pinSelected}/> : <img src={pin}/>}
                </div>
            </div>}
        </div>
    </div>
}

export default Index