import {Get, Post} from "./index";

let priceController;

const GetPrice = (vehicleId, stationFromId, stationToId, count) => {
    if (priceController) {
        priceController.abort("deduplication")
    }
    priceController = new AbortController()
    return Get("payments", {
        vehicle_id: vehicleId,
        station_from_id: stationFromId,
        station_to_id: stationToId,
        count,
    }, priceController.signal).catch(error => {
        if (error instanceof Response) {
            if (error.status === 416) {
                throw new RangeError();
            }
        }
    })
}

const Pay = (vehicleId, stationFromId, stationToId, count, price, autoPayment) => {
    return Post("payments", null, {
        vehicle_id: vehicleId,
        station_from_id: stationFromId,
        station_to_id: stationToId,
        count,
        price,
        auto_payment: autoPayment,
    })
}

export {GetPrice, Pay}