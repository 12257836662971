import {Get, Delete} from "./index";

let cardsController;

const GetCards = () => {
    if (cardsController) {
        cardsController.abort("deduplication")
    }
    cardsController = new AbortController()
    return Get("payments/cards", null, cardsController.signal)
}

const RemoveCard = (aid, id) => {
    return Delete("payments/cards/" + aid + "/" + id)
}

export {GetCards, RemoveCard}
