import {Link, useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState, useRef} from "react";
import {GetCards, RemoveCard} from "../api/cards";
import back from "../icons/back.svg"
import mirIcon from "../icons/mir.svg"
import visaIcon from "../icons/visa.png"
import mastercardIcon from "../icons/mastercard.png"
import Moment from "react-moment";
import 'moment/locale/ru';
import removeIcon from "../icons/remove.svg"
import closeIcon from "../icons/close.svg"
import removeBigIcon from "../icons/remove-big.svg"
import notFoundIcon from "../icons/notfound.svg";
import Loader from "../components/loader";
import Header from "../components/header";

const Cards = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const [cards, setCards] = useState(props.cards || [])
    const [loading, setLoading] = useState(false)
    const [waitRemoveApply, setWaitRemoveApply] = useState(null)
    const loadCards = (data) => {
        setLoading(true)
        GetCards().then(setCards).catch(() => {
        }).finally(() => setLoading(false))
    }

    useEffect(() => {
        if (cards.length === 0) {
            loadCards()
        }
    }, [])


    const goBack = () => {
        if (props.back) {
            props.back()
            return
        }
        navigate(-1)
    }

    const removeCard = (card) => {
        RemoveCard(card.acquiring_id, card.id).then((e)=>{
            setCards(cards.filter(c=>c.id !== card.id && c.acquiring_id !== card.acquiring_id))
            props.onCardRemoved && props.onCardRemoved(card.id)
        }).finally(()=>setWaitRemoveApply(null))
    }

    return <div className={"page"}>
        {waitRemoveApply && <div>
            <div style={{background: "#0000004D", position: "fixed", top: 0, left: 0, right: 0, bottom: 0}}></div>
            <div style={{
                background: "white",
                position: "fixed",
                borderTopLeftRadius: 16,
                borderTopRightRadius: 16,
                left: 0,
                right: 0,
                bottom: 0,
                height: 284,
                zIndex: 9999
            }}>
                <button onClick={() => setWaitRemoveApply(null)}
                        style={{position: "absolute", right: 16, top: 16, padding: 8, border: 0, background:"#F1F1F1", borderRadius: 8}}>
                    <img src={closeIcon} style={{display: "block"}}/>
                </button>
                <div style={{textAlign: "center", marginTop: 42}}>
                    <img src={removeBigIcon}/>
                    <div style={{fontSize:22, fontWeight:600, marginTop: 22, letterSpacing: "-0.018em"}}>Точно хотите удалить карту?</div>
                    <div style={{display:"flex", gap: 8, paddingLeft: 16, paddingRight:16, justifyContent:"center", marginTop:32}}>
                    <button onClick={() => setWaitRemoveApply(null)} style={{fontSize:16, fontWeight:600,border:0, borderRadius:10,width:"100%", height:48, color:"#1BA136",background: "#1414140F"}}>Нет</button>
                    <button onClick={() => removeCard(waitRemoveApply)} style={{fontSize:16, fontWeight:600,border:0, borderRadius:10,width:"100%", height:48, color:"white",background: "#1DB93C"}}>Да</button>
                    </div>
                </div>

            </div>
        </div>}

        <Header onBack={props.back} title={"Мои карты"}/>
        <Loader
            visible={loading}
        />

        {cards.length === 0 && <div style={{textAlign: "center", marginTop: 192}}>
            <img src={notFoundIcon}/>
            <div style={{textAlign: "left", position: "fixed", bottom: 32}}>
                <div style={{fontSize: 19, fontWeight: 600, letterSpacing: "-0.02em"}}>Нет привязанных средств оплаты</div>
                <div style={{fontSize: 16, letterSpacing: "-0.015em"}}>Здесь отображаются привязанные средства оплаты</div>
            </div>
        </div>}
        <div className={"cards-list"}>
            {cards.map((c, i) => <div className={"cards-list-item"} key={i}>
                <div className={"cards-list-item-type"}>
                    {c.pan[0] === "4" && <img alt={"Visa"} src={visaIcon}/>}
                    {c.pan[0] === "5" && <img alt={"MasterCard"} src={mastercardIcon}/>}
                    {c.pan[0] === "2" && <img alt={"МИР"} src={mirIcon}/>}
                </div>
                <div className={"cards-list-item-info"}>
                    <div className={"cards-list-item-info-pan"}>{c.pan.replace("******"," ···· ")}</div>
                    <div className={"cards-list-item-info-expiry"}><Moment
                        format={"MM/YYYY"}>{c.expiry_at}</Moment></div>
                </div>
                <div className={"cards-list-item-control"}>
                    <button className={"cards-list-item-control-remove"} onClick={() => setWaitRemoveApply(c)}><img
                        alt="Удалить" src={removeIcon}/></button>
                </div>
            </div>)
            }
        </div>
    </div>
}


export default Cards