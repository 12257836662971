import {API, Get, makeSearchParams} from "./index";

let vehicleController;

const GetVehicles = (lat,lng,points,search) => {
    if (vehicleController) {
        vehicleController.abort("deduplication")
    }
    vehicleController = new AbortController();
    return Get("vehicles", {
        ...lat ? {lat} :{},
        ...lng ? {lng} :{},
        ...points ? {points} :{},
        ...search ? {search} :{},
        with: ['Route', 'Station']
    }, vehicleController.signal)
}

export {GetVehicles}