import {useEffect, useState} from "react";
import {useSearchParams, useNavigate} from 'react-router-dom';
import Search from "../components/search";
import Vehicle from "../components/vehicle";
import {GetVehicles} from "../api/vehicles";
import {GetPrice, Pay} from "../api/payment";
import Loader from '../components/loader'
import Header from '../components/header'
import ticketIcon from '../icons/ticket.svg'
import arrowRightIcon from '../icons/arrow-right.svg'
import busDarkIcon from '../icons/bus-dark.svg'
import pointIcon from '../icons/point.svg'
import checkIcon from '../icons/check.svg'
import {GetCards} from "../api/cards";
import visaIcon from "../icons/visa.png";
import mastercardIcon from "../icons/mastercard.png";
import mirIcon from "../icons/mir.svg";
import Cards from "./cards";
import Passengers from "../components/passengers";
import Offer from '../components/offer'
import Approve from './approve'


const HistoryLink = () => {
    const navigate = useNavigate()
    return <div className={"tickets"} onClick={e => {
        navigate("/orders")
    }}>
        <div className={"tickets-icon"}>
            <img src={ticketIcon}/>
        </div>
        <div className={"tickets-title"}>
            <div className={"tickets-title-title"}>Мои поездки</div>
            <div className={"tickets-title-subtitle"}>Активные и история поездок</div>
        </div>
        <div className={"tickets-link"}>
            <img src={arrowRightIcon}/>
        </div>
    </div>
}

const Price = ({cards, setShowCards, price, count, disabled, pay}) => <div style={{display: "flex", gap:8}}>
    {cards.length > 0 && <div onClick={() => setShowCards(true)} style={{
        background: "#1414140F",
        paddingTop: 8,
        borderRadius: 10,
        minWidth: 80,
        fontSize: 13
    }}>
        {cards[0].pan[0] === "4" && <img alt={"Visa"} style={{height: 12}} src={visaIcon}/>}
        {cards[0].pan[0] === "5" &&
            <img alt={"MasterCard"} style={{height: 12}} src={mastercardIcon}/>}
        {cards[0].pan[0] === "2" && <img alt={"МИР"} style={{height: 12}} src={mirIcon}/>}
        <div style={{color: "#898989"}}>····&nbsp;{cards[0].pan.slice(-4)}</div>
    </div>}
    <div style={{width: "100%"}}>
        <button className={"payment-button"} disabled={disabled} onClick={e => pay()}>
            <span>Оплатить</span>{price !== null ? <span> · {price / 100} ₽</span> : null}
        </button>
    </div>
</div>

const Vehicles = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const [count, setCount] = useState(1)
    const [price, setPrice] = useState(null)
    const [cards, setCards] = useState([])
    const [allowAutoPayment, setAllowAutoPayment] = useState(false)
    const [canAutoPayment, setCanAutoPayment] = useState(false)
    const [vehicle, setVehicle] = useState(null)
    const [vehicles, setVehicles] = useState([])
    const [loading, setLoading] = useState(false)
    const [location] = useState({lat: parseFloat(searchParams.get("lat")), lng: parseFloat(searchParams.get("lng"))})
    const [points] = useState(searchParams.get("points"))
    const [stationSelection, setStationSelection] = useState()
    const [search, setSearch] = useState(null)
    const [formURL, setFormURL] = useState()

    const [searchDepartureEnabled, setSearchDepartureEnabled] = useState(false)
    const [searchArrivalEnabled, setSearchArrivalEnabled] = useState(false)
    const [departureStation, setDepartureStation] = useState(null)
    const [arrivalStation, setArrivalStation] = useState(null)
    const [stationSearch, setStationSearch] = useState('')
    const [showCards, setShowCards] = useState(false)
    const [showApprove, setShowApprove] = useState(false)
    const loadVehicles = () => {
        setLoading(true)
        GetVehicles(location.lat, location.lng, points, search)
            .then(setVehicles)
            .finally(() => setLoading(false))
            .catch(e => {
            })
    }
    const getPrice = () => {
        setLoading(true)
        GetPrice(vehicle.id, departureStation?.id || vehicle.station_id, arrivalStation?.id, count).then(data => {
            setPrice(data.price)
            setCanAutoPayment(data.can_auto_payment)
        }).catch(error => {
            if (error instanceof RangeError) {
                setPrice(null)
            }
        }).finally(() => setLoading(false))
    }

    const pay = () => {
        if (canAutoPayment && !showApprove && cards.length === 0) {
            setShowApprove(true)
            return
        }

        setLoading(true)
        Pay(vehicle.id, departureStation?.id || vehicle.station_id, arrivalStation?.id, count, price, cards.length > 0 || allowAutoPayment)
            .then(data => {
                setFormURL(data.url)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getCards = () => {
        GetCards().then(setCards).catch(() => {
        })
    }

    const setStation = (station) => {
        if (searchArrivalEnabled) {
            setArrivalStation(station)
        }
        if (searchDepartureEnabled) {
            setDepartureStation(station)
        }
        setSearchArrivalEnabled(false)
        setSearchDepartureEnabled(false)
    }
    useEffect(() => {
        getCards()
    }, [])

    useEffect(() => {
        loadVehicles();
    }, [location, points, search])

    useEffect(() => {
        if (formURL) {
            window.location.href = formURL;
        }
    }, [formURL]);

    useEffect(() => {
        if (vehicle) {
            setStationSelection(false);
            getPrice(vehicle.id, vehicle.station.id);
        }
    }, [vehicle]);

    useEffect(()=>{
        if (vehicle) {
            getPrice()
        }
    },[count])


    useEffect(() => {
        if (search) {
            setLoading(true)
            GetVehicles(null, null, points, search).then(setVehicles).finally(() => setLoading(false))
        } else if (search === "") {
            loadVehicles()
        }
    }, [search])

    useEffect(() => {
        if (vehicles.length === 1) {
            setVehicle(vehicles[0])
        }
    }, [vehicles]);

    useEffect(() => {
        if (vehicle) {
            getPrice()
        }
    }, [departureStation, arrivalStation])

    useEffect(() => {
        if (!stationSelection) {
            setArrivalStation(null)
            setDepartureStation(null)
            setSearchArrivalEnabled(null)
            setSearchDepartureEnabled(null)
        }
    }, [stationSelection])

    if (showCards) {
        return <Cards back={() => setShowCards(false)} cards={cards}
                      onCardRemoved={id => setCards(cards.filter(c => c.id !== id))}/>
    }

    if (showApprove) {
        return <div>
            <Approve savePayment={allowAutoPayment} setSavePayment={setAllowAutoPayment}
                     onBack={() => setShowApprove(false)}/>
            <div className={"payment"}>
                <Passengers count={count} setCount={setCount}/>
                <Price price={price} count={count} cards={cards} setShowCards={setShowCards}
                       disabled={!vehicle || loading} pay={pay}/>
                <Offer/>
            </div>
        </div>
    }


    return <div className={"container page"}>
        <Header title={"Оплата проезда"} onBack={stationSelection ? () => {
            setStationSelection(false)
            setVehicle(null)
            setPrice(null)
        } : null}/>
        <Loader visible={loading}/>
        {!stationSelection && <div>
            <HistoryLink/>
            <div className={"vehicles"}>
                <Search search={setSearch} loadVehicles={loadVehicles}/>
                {
                    vehicles.map((v, i) => <Vehicle
                        key={i}
                        vehicle={v}
                        selectedVehicle={vehicle}
                        setVehicle={setVehicle}
                        loading={loading}/>)
                }
            </div>
        </div>}
        {stationSelection && !searchDepartureEnabled && !searchArrivalEnabled && <div>
            <div style={{fontSize: 13, paddingLeft: 32, paddingTop: 16, color: "#898989"}}>Маршрут</div>
            <div className={"vehicles"} style={{paddingTop: 0, marginTop: 8}}>
                <Vehicle vehicle={vehicle} loading={loading}/>
            </div>
            <div style={{fontSize: 13, paddingLeft: 32, marginTop: 16, color: "#898989"}}>Путь</div>
            <div className={"vehicles"} style={{paddingTop: 0, marginTop: 8}}>
                <div style={{display: "flex", paddingTop: 12, justifyContent: "space-between"}}
                     onClick={() => setSearchDepartureEnabled(true)}>
                    <div>
                        <img src={busDarkIcon} style={{paddingLeft: 8, paddingTop: 8}}/>
                    </div>
                    <div style={{paddingLeft: 16, width: "100%"}}>
                        <div>{departureStation ? departureStation.name : "Отправление"}</div>
                        <div style={{
                            color: "#898989",
                            fontSize: 14
                        }}>{departureStation ? "Остановка отправления" : "Выберите остановку"}</div>
                    </div>
                    <div style={{color: "#1BA136", paddingTop: 12, paddingRight: 8}}>
                        {departureStation ? <img src={checkIcon}/> : "Выбрать"}
                    </div>
                </div>
                <div>
                    <hr style={{border: "0.5px solid #8989894D", marginLeft: 48, marginRight: 8}}/>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}
                     onClick={() => setSearchArrivalEnabled(true)}>
                    <div>
                        <img src={busDarkIcon} style={{paddingLeft: 8, paddingTop: 8}}/>
                    </div>
                    <div style={{paddingLeft: 16, width: "100%"}}>
                        <div>{arrivalStation ? arrivalStation.name : "Прибытие"}</div>
                        <div style={{
                            color: "#898989",
                            fontSize: 14
                        }}>{arrivalStation ? "Остановка прибытия" : "Выберите остановку"}</div>
                    </div>
                    <div style={{color: "#1BA136", paddingTop: 12, paddingRight: 8}}>
                        {arrivalStation ? <img src={checkIcon}/> : "Выбрать"}
                    </div>
                </div>
            </div>
        </div>}

        {searchArrivalEnabled &&
            <div className={"vehicles"} style={{paddingTop: 8, marginTop: 8}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <img src={busDarkIcon} style={{paddingLeft: 8, paddingTop: 8}}/>
                    </div>
                    <div style={{paddingLeft: 16, width: "100%"}}>
                        <div><input type={"text"}
                                    className={"nofocus"}
                                    onChange={e => setStationSearch(e.target.value)}
                                    autoFocus
                                    placeholder={"Отправление"}/></div>
                        <div style={{color: "#898989", fontSize: 14}}>Выберите остановку</div>
                    </div>
                </div>
            </div>
        }

        {searchDepartureEnabled &&
            <div className={"vehicles"} style={{paddingTop: 8, marginTop: 8}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <img src={busDarkIcon} style={{paddingLeft: 8, paddingTop: 8}}/>
                    </div>
                    <div style={{paddingLeft: 16, width: "100%"}}>
                        <div>
                            <input type={"text"}
                                   className={"nofocus"}
                                   onChange={e => setStationSearch(e.target.value)}
                                   autoFocus
                                   placeholder={"Прибытие"}/></div>
                        <div style={{color: "#898989", fontSize: 14}}>Выберите остановку</div>
                    </div>
                </div>
            </div>
        }
        {(searchArrivalEnabled || searchDepartureEnabled) && <div className={"vehicles"}>
            {vehicle?.attributes?.stations.filter(s => stationSearch === '' || s.name.toUpperCase().indexOf(stationSearch.toUpperCase()) !== -1).map((s, i) =>
                <div key={i} style={{display: "flex"}} onClick={() => setStation(s)}>
                    <div style={{paddingTop: 20, paddingLeft: 8, paddingRight: 12}}><img src={pointIcon}/></div>
                    <div style={{lineHeight: "64px"}}>{s.name}</div>
                </div>)}
        </div>}


        {!searchArrivalEnabled && !searchDepartureEnabled && <div className={"payment"}>
            {price !== null && <Passengers count={count} setCount={setCount}/>}
            {!vehicle && <button className={"payment-button"} disabled={true}>Выберите маршрут</button>}
            {price !== null && <Price price={price} count={count} cards={cards} setShowCards={setShowCards}
                                      disabled={!vehicle || loading} pay={pay}/>}
            {vehicle && price === null && !stationSelection &&
                <button className={"payment-button"}
                        onClick={() => setStationSelection(true)}>Далее</button>}
            {vehicle && price === null && stationSelection && (!departureStation || !arrivalStation) &&
                <button className={"payment-button"} disabled={true}>Укажите путь</button>}
            {price !== null ? <Offer/> : <div style={{height: 34}}></div>}
        </div>}
    </div>
}

export default Vehicles