import reloadIcon from "../../icons/reload.svg"
import './style.css'
import {useEffect, useRef, useState} from "react";
import clearIcon from "../../icons/clear.svg"
import SearchInput from 'react-search-input'


const Index = ({search, loadVehicles}) => {
    const [focused, setFocused] = useState(false)

    return <div className={"search-toolbar"}>
        <SearchInput
            type={"text"}
            className={"search-input"}
            placeholder={focused ? "Укажите бортовой или гос. номер": "Найти по номеру"}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)} onChange={search}/>
        {!focused && <button className={"refresh-button"} onClick={loadVehicles}>
            <img alt={""} src={reloadIcon}/>
        </button>}
    </div>
}

export default Index