import passengers from "../../icons/passengers.svg";
import "./style.css"
import plusIcon from "../../icons/plus.svg"
import minusIcon from "../../icons/minus.svg"
import minusDisabledIcon from "../../icons/minus-disabled.svg"

const Index = ({count, setCount}) => <div className={"payment-count"}>
    <div><img style={{display: "block"}} src={passengers}/></div>
    <div style={{fontSize: 15}}>Количество&nbsp;пассажиров</div>
    <div>
        <div className={"payment-count-control"}>
            <img onClick={e => setCount(Math.max(1, count - 1))} src={count === 1 ? minusDisabledIcon : minusIcon}
                 style={{display: "block"}}/>
            <div className="payment-count-count">{count}</div>
            <img onClick={e => setCount(count + 1)} src={plusIcon} style={{display: "block"}}/>
        </div>
    </div>
</div>


export default Index