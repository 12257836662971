import arrow from "../../icons/arrow.svg";
import './style.css'

const Index = () => <div className={"offer-link"}>
    <div>
        <a>Договор оферта</a>
    </div>
    <div>
        <a><img src={arrow}/></a>
    </div>
</div>


export default Index