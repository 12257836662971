import {API, Get, makeSearchParams} from "./index";

let orderController;

const GetOrder = (id) => {
    if (orderController) {
        orderController.abort("deduplication")
    }
    orderController = new AbortController()
    return Get("orders/" + id,null, orderController.signal)
}

export {GetOrder}
